<template>
    <div>
        <h4 class="bold text-uppercase">{{ packageSelect.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link / Id:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID tạo dịch vụ"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chọn gói cần mua:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select v-model="packageIndex" class="custom-select select-light">
                                    <option v-for="(item, index) in packageSelect.package" :key="index" :value="item"
                                        >{{ item.name }} - {{ item.prices | formatNumber }} {{ currency }}</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="prices"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ mua gói {{ packageIndex.name }} với giá
                                            <span class="green bold">{{ prices | formatNumber }}</span> {{ currency }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p v-html="packageSelect.notes"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <facebooklogs :panel="panel" :packageSelect="packageSelect"></facebooklogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "../../../helpers"
import { outSiteV2Create } from "../../../api/services/other"
import facebooklogs from "./out-site-v2-logs"

export default {
    name: "facebook-order",
    components: { facebooklogs },
    data() {
        return {
            object_id: "",
            notes: "",
            panel: "",
            packageSelect: {},
            packageIndex: {}
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        configSite() {
            return this.$store.state.site.site.config || false
        },
        menusSite() {
            return this.$store.state.site.menus
        },
        menuIndex() {
            return this.$store.state.site.menuIndex
        },
        prices() {
            return this.packageIndex.prices
        }
    },
    async created() {
        this.getPackage()
    },
    watch: {
        menuIndex() {
            this.getPackage()
        }
    },
    methods: {
        getPackage() {
            this.menusSite.forEach(menu => {
                if (menu.alias_name == this.menuIndex.tool_alias) {
                    this.packageSelect = menu
                    if (menu.package) {
                        this.packageIndex = menu.package[0]
                    }
                }
            })
        },
        order: async function() {
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.packageIndex.name} với giá ${this.prices} ${this.currency} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link: this.object_id,
                prices: this.prices,
                notes: this.notes,
                package_name: this.packageIndex.package_name
            }
            let data = await outSiteV2Create(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
