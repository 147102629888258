<template>
    <div>
        <div
            v-if="selectOrder"
            id="update-order"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="bankAccount"
            aria-hidden="true"
            style="display: none;"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title bold">Cập nhật cho {{ selectOrder.username }}</h4>
                    </div>
                    <form @submit.stop.prevent="outSiteV2Update">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="bold red">Link bài viết:</label>
                                <input type="text" v-model="selectOrder.link" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label class="bold red">Trạng thái:</label>
                                <select v-model="selectOrder.status" class="custom-select">
                                    <option value="0">Chưa chạy</option>
                                    <option value="1">Bắt đầu chạy</option>
                                    <option value="2">Chạy xong</option>
                                    <option value="-1">Hủy đơn</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">
                                Hủy bỏ
                            </button>
                            <button type="submit" :class="['btn btn-dark-blue waves-effect waves-light']">
                                Cập Nhật
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { outSiteV2Update } from "../../../api/services/other"
import { catchError } from "../../../helpers"
export default {
    name: "facebook-logs",
    components: {},
    props: {
        selectOrder: Object
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        currency() {
            return this.$store.state.site.site.currency
        }
    },
    methods: {
        outSiteV2Update: async function() {
            let postData = {
                link: this.selectOrder.link,
                status: this.selectOrder.status,
                id: this.selectOrder.id
            }
            let data = await outSiteV2Update(postData)
            if (data.status === 200 && data.success) {
                $("#update-order").modal("hide")
                this.$emit("reload")
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        }
    }
}
</script>
