import { request } from "@/utils/request"

export function orderSpamSMS(data) {
    return request({
        url: "api/spam-sms/create",
        method: "post",
        data
    })
}
export function getListSpamSMS() {
    return request({
        url: "api/spam-sms",
        method: "get"
    })
}
export function convertPhoneUID(data) {
    return request({
        url: "api/convert/convert-uid-phone",
        method: "post",
        data
    })
}
export function outSiteV2Create(data) {
    return request({
        url: "api/out-site-v2/create",
        method: "post",
        data
    })
}
export function outSiteV2Logs(params) {
    return request({
        url: "api/out-site-v2/list" + params,
        method: "get"
    })
}
export function outSiteV2Update(data) {
    return request({
        url: "api/out-site-v2/update/" + data.id,
        method: "post",
        data
    })
}
