<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getListJobOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListJobOrder()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable stt="true" :columns="columnsTableLogs" :rows="data">
            <th v-if="user.role == 'admin'" slot="thead-tr-o" class="text-center" style="vertical-align: middle">
                Thao Tác
            </th>
            <td
                v-if="user.role == 'admin'"
                slot="tbody-tr-o"
                slot-scope="props"
                class="text-center"
                style="vertical-align: middle"
            >
                <button @click="outSiteV2Update(props.row)" class="btn btn-info btn-sm ml-2">
                    <i class="fas fa-edit"></i> Cập nhật
                </button>
            </td>
        </datatable>
        <outsitev2update @reload="getListJobOrder" :selectOrder="selectOrder"></outsitev2update>
    </div>
</template>
<script>
import { outSiteV2Logs } from "../../../api/services/other"
import datatable from "../../../components/datatable/datatable"
import outsitev2update from "./out-site-v2-update"
export default {
    name: "facebook-logs",
    components: {
        datatable,
        outsitev2update
    },
    props: {
        panel: String,
        packageSelect: Object
    },
    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                { label: "Username", field: "username" },
                { label: "ID Seeding", field: "link", link_link: true },
                { label: "Tổng tiền", field: "prices", numeric: true },
                { label: "Trạng thái", field: "status_html", html: true },
                { label: "Ghi chú", field: "notes", inputtext: true },
                { label: "Khởi tạo", field: "created_at", timeago: true }
            ],
            selectOrder: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        currency() {
            return this.$store.state.site.site.currency
        },
        menuIndex() {
            return this.$store.state.site.menuIndex
        }
    },
    watch: {
        panel(value) {
            if (value == "logs") {
                this.getListJobOrder()
            }
        },
        menuIndex() {
            if (this.panel === "logs") {
                this.getListJobOrder()
            }
        }
    },
    methods: {
        outSiteV2Update: async function(item) {
            this.selectOrder = Object.assign({}, item)
            $("#update-order").modal("show")
        },
        getListJobOrder: async function() {
            let params =
                "?" + this.packageSelect.alias_name + (this.object_id_search ? "&link=" + this.object_id_search : "")
            let data = await outSiteV2Logs(params)
            this.data = data.data.map(item => {
                item.status_html =
                    item.status === 0
                        ? `<span class="badge badge-info"><i class="material-icons font-14 icon-log">info</i>Chưa chạy</span>`
                        : item.status === 1
                        ? `<span class="badge badge-yellow"><i class="material-icons font-14 icon-log">autorenew</i>Đang chạy</span>`
                        : item.status === 2
                        ? `<span class="badge badge-success"><i class="material-icons font-14 icon-log">beenhere</i>Hoàn thành</span>`
                        : `<span class="badge badge-danger"><i class="material-icons font-14 icon-log">delete_forever</i>Đã hủy</span>`
                return item
            })
        }
    }
}
</script>
